
//Add dummy console function if not one present
if(!window.console){
	window.console = {};
}
if(!console.log){
	console.log = function(){};
}

var ignore_scroll = false;

//temp
window.onerror = function(msg, url, line, col, error) {
	var extra = !col ? '' : '\ncolumn: ' + col;
	extra += !error ? '' : '\nerror: ' + JSON.stringify(error);
	alert("Error: " + msg + "\nurl: " + url + "\nline: " + line + extra);
};

/*
//Disable fast click due to causing ie bugs,  and probably not needed for modern mobile browsers
//Run fast click
$(function() {
    var attachFastClick = Origami.fastclick;
	attachFastClick(document.body);
});
*/

window['animate'] = (function(){

	var requestAnimFrame = 
		window.requestAnimationFrame || 
  		window.webkitRequestAnimationFrame || 
  		window.mozRequestAnimationFrame || 
  		function(callback){ 
  			window.setTimeout(function(){
	  			var date = new Date();
	  			callback(date.getTime());
	  		}, 15);
	  	};

	var easingFunctions = {
		'linear': function (t) { return t },
		'easeInQuad': function (t) { return t*t },
		'easeOutQuad': function (t) { return t*(2-t) },
		'easeInOutQuad': function (t) { return t<.5 ? 2*t*t : -1+(4-2*t)*t },
		'easeInCubic': function (t) { return t*t*t },
		'easeOutCubic': function (t) { return (--t)*t*t+1 },
		'easeInOutCubic': function (t) { return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1 },
		'easeInQuart': function (t) { return t*t*t*t },
		'easeOutQuart': function (t) { return 1-(--t)*t*t*t },
		'easeInOutQuart': function (t) { return t<.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t },
		'easeInQuint': function (t) { return t*t*t*t*t },
		'easeOutQuint': function (t) { return 1+(--t)*t*t*t*t },
		'easeInOutQuint': function (t) { return t<.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t },
		'easeOutElastic': function(t) {var p = 0.3; return Math.pow(2,-10*t) * Math.sin((t-p/4)*(2*Math.PI)/p) + 1;}
	}

	return function(info, arg2)
	{
		if(typeof info === 'function'){
			info = {
				each:info,
				duration:arg2
			}
		}

		//State
		var startTime = 0;
		var currentTime = 0;
		var completed = false;
		var showedFirst = false;

		//Options
		var delay = info['delay'] || 0;
		var firstcallback = info['first'];
		var duration = info['duration'] || 300;
		var easingFunc = easingFunctions[info['easing']] || easingFunctions['easeInOutQuad'];
		var progresscallback = info['each'];
		var endcallback = info['last'];
		var startpaused = info['paused'];

		//Next animate object
		var nextanimate = null;

		function animate_inner(timestamp){

			//Ignore if already completed
			if(completed)return;

			//Get time
      		if(startTime == 0)startTime = timestamp;
      		currentTime = timestamp;

			//Get elapsed time
			var elapsed = (currentTime-startTime-delay) / duration;
			if(elapsed > 1)elapsed = 1;

			//If passed animation start time
		    if(elapsed >= 0){

		    	//If not yet showed first frame
		    	if(!showedFirst){
		    		showedFirst = true;

					//Run first callback
					if(firstcallback){
						firstcallback();
						firstcallback = null;
					}

					//Reinititialise start time, so that next frame will again show first position (in case of jump)
					startTime = 0;
		    	}

		    	var eased = easingFunc(elapsed);
		    	var testob = {
		    		progresscallback:progresscallback,
		    		progress:elapsed,
		    		eased:easingFunc(elapsed),
		    		tween:function(from, to){
				    	return from+(to-from)*eased;
				    },
		    		first:startTime==0,
		    		last:elapsed==1
		    	}
		    	testob.progresscallback(testob.tween);

		    	/*
		    	//Run the progress callback
			    var eased = easingFunc(elapsed);
			    progresscallback(function(from, to){
			    	return from+(to-from)*eased;
			    });
			    */
			}

			//If not finished, get next frame
			if(elapsed != 1){
		      	requestAnimFrame(animate_inner);
			}
			//Otherwise run the final callback
			else{
				if(endcallback)endcallback();
				completed = true;
				if(nextanimate){
					nextanimate['start']();
				}
			}
		}

		if(!startpaused){
			requestAnimFrame(animate_inner);
		}

		return {

			//Chain
			'animate':function(info){
				info.paused = true;
				nextanimate = animate(info);
				return nextanimate;
			},

			'stopped':function(){
				return completed;
			},

			//Control
			'finish':function(){
				if(completed)return;

				//Complete this animation (calling all callbacks) and recurse
				if(firstcallback)firstcallback();
				progresscallback(function(a,b){return b;});
				if(endcallback)endcallback();
				completed = true;
				if(nextanimate)nextanimate['finish']();
			},
			'stop':function(){
				completed = true;
			},
			'start':function(){
				//animate_inner();

				requestAnimFrame(animate_inner);
			}
		}
	}

})();


function load_gallery(){

	function expand_gallery(galleryBlock)
	{
		//var galleryBlock = $(this).closest('.gallery2-block');

		//Expand the gallery, and load images 
		galleryBlock.addClass('expanded');

		galleryBlock.find('.extra').css({ 
			//'transform':'scaleY(0.8)', 
			'transform':'translateY(-20px)',
			//'-webkit-filter': 'blur(10px)',
			'opacity':0,
		});
		setTimeout(function(){
			galleryBlock.find('.extra').css({
				//'transform':'scale(1)',
				'transform':'translateY(0px)',
				//'-webkit-filter': 'blur(0px)',
				'opacity':1
			});
		}, 0);

		lazyLoadInner(true);
	}

	//When clickong on the expand button
	$('body').on('click', '.gallery2-block .show', function(){
		expand_gallery($(this).closest('.gallery2-block'));
	});

	$('body').on('click', '.gallery2-block .hide', function(){

		//Expand the gallery, and load images
		$(this).closest('.gallery2-block').removeClass('expanded');

	});


	$('body').on('click', '.pswp__button.pswp__button--close' , function(){
		//alert(1);
	    //$(this).closest('.gallery2-block').removeClass('expanded');
	}); 
 
	$('body').on('click', '.main-logo', function(){

		var img = $(this).find('img');
		//console.log(img);
		var items = [{src:img.data('src'), msrc:img.attr('src'), w:640, h:640, title:'', thumbnail_element:img[0]}];
		//console.log(items);

		var thing = new PhotoSwipe( $('.pswp')[0], PhotoSwipeUI_Default, items, {
			bgOpacity: 0.8,
			index: 0,
			tapToClose: true,
			fullscreenEl: false,
			shareEl: false,
			getThumbBoundsFn: function(index){
				var item = $(items[0].thumbnail_element);
				var pos = {x:item.offset().left, y:item.offset().top, w:item.width()};
				
				if(app_mode()){
					pos.y -= 54;
				}

				if(app_mode() && window.scroll_pos){
					pos.x += window.scroll_pos.x;
					pos.y += window.scroll_pos.y;
				}
				
				return pos;
			}
		});

		// Initialise
		thing.init();
	});

	//When clicking on a gallery item
	$('body').on('click', '.gallery2-block img', function(){


		//Alter the position of the gallery container
		if(app_mode() && window.scroll_pos){
			$('.pswp').css({
				'position':'absolute',
				'right':'auto',
				'bottom':'auto',
				'top':window.scroll_pos.y,
				'left':window.scroll_pos.x,
				'height':window.scroll_pos.h,
				'width':window.scroll_pos.w
			});
		}


		//Expand the gallery, and load images
		//$(this).closest('.gallery2-block').addClass('expanded');
		//lazyLoadInner(true);

		//Get gallery items, and index of this image
		var galleryBlock =  $(this).closest('.gallery2-block');
		var gallery = galleryBlock.find('img');
		var index = gallery.index(this);
		if(direction == 'rtl')index = gallery.length-index-1;

		//Create gallery list
		var items = [];
		gallery.each(function(){

			var $this = $(this);
			var w = parseInt($this.attr('width'),10);
			var h = parseInt($this.attr('height'),10);
			var p = $this.closest('.image');
			var full_w = p.data('width');
			var full_h = p.data('height');
			var full_src = p.data('src');

			//TODO - alter source? to use hyperlink instead?
			//var src = $this.attr('src');
			var src = $this.css('background-image');
			//5 2
			src = src.substring(5, src.length-2);

			var title = $this.parent().attr('title');

			//items.push({src:src, msrc:src, w:w, h:h, title:$this.attr('title')});
			items.push({src:full_src, msrc:src, w:full_w, h:full_h, title:title, thumbnail_element:this});
		});
		if(direction == 'rtl')items = items.reverse();

		console.log(items);

		// Create gallery handler
		var thing = new PhotoSwipe( $('.pswp')[0], PhotoSwipeUI_Default, items, {
			bgOpacity: 0.8,
			index: index,
			tapToClose: true,
			fullscreenEl: false,
			shareEl: false,
			focus: false, //disable photoswipe focus...
			history: false, //disable history usage...
			showAnimationDuration:333,
			//showHideOpacity:true,
			getThumbBoundsFn: function(index){

				var item = $(thing.items[index].thumbnail_element);

				var pos = {x:item.offset().left, y:item.offset().top, w:item.width()};

				//add scroll pos?
				if(app_mode()){
					pos.y -= 54;
				}


				if(app_mode() && window.scroll_pos){
					pos.x -= window.scroll_pos.x;
					pos.y -= window.scroll_pos.y;
				}

				//if(item.is(':visible')){
				//	console.log(item.is(':visible'));
				//}

				return pos;
			}
		});

		//When slide changes
		thing.listen('afterChange', function(){ 
			//console.log(thing.currItem);
			if(!$(thing.currItem.thumbnail_element).is(':visible')){

				expand_gallery(galleryBlock);
			}
		});

		// Initialise
		thing.init();

		//alert(JSON.stringify(thing.options));
	});

}

function display_canonical_url(){
	//Update current url to the canonical url
	return;

	var title = $(document).attr('title');
	var canonical_url = $("link[rel='canonical']").attr("href");

	var current_url = $(location).attr('href');
	//var current_params = $(location).attr('search');
	var current_params = '';

	if(canonical_url && (canonical_url+current_params) != current_url){
		var state = get_state();
		replace_state(state, title, canonical_url+current_params);
	}
}

function google_analytics(){

	if(debug)return;

	(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
	(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
	m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
	})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

	ga('create', 'UA-34565917-1', 'auto');
	//ga('set', 'is_app', is_app);
	//ga('send', 'pageview');
	ga('send', 'pageview', {
		'is_app':is_app
	});

	//TODO: add custom thing to indicate that this is from the app?

	/*
	//Google analytics
	var _gaq = _gaq || [];
	_gaq.push(['_setAccount', 'UA-34565917-1']);
	_gaq.push(['_trackPageview']);
	
	(function() {
		var ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
		ga.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'stats.g.doubleclick.net/dc.js';
		var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);
	})();		
	*/
}




function search_bar_init()
{
	//On state change
	on_state(function(state){
		
		//Show/Hide the search dialog
		if(state.show_search && !$('body').hasClass('search-show')){

			//Start animating
			$('body').addClass('search-showing');			

			//Reflow
			$('#search-results .content')[0].offsetWidth;

			//Animate
			$('body').addClass('search-show');			
		}
		else if(!state.show_search && $('body').hasClass('search-show')){

			$('body').removeClass('search-show');
			setTimeout(function(){
				$('body').removeClass('search-showing');
			}, 500);
		}

		//If input text changed, then set it
		if(state.searchInput && state.searchInput != $('#search-input').val()){

			$('#search-input').val(state.searchInput);
		}

		if(state.show_search){
			update_search();
		}
	});

	//Show search list on focus
	$('#search-input').focus(function(e){
		var state = get_state();
		if(state.show_search)return;

		state.show_search = true;
		if(state.show_browse){
			state.show_browse = false;
			replace_state(state);
		}
		else{
			push_state(state);
		}
	});

	//Don't let click on search input propogate to document (and so cause search cancel)
	$('#search-input').on('click', function(e){
		e.stopPropagation();
	});

	//If a click reaches the document
	$(document).on('click', function(e){
		if($(e.target).closest('#search-results').length != 0)return;

		//If search is open, pop it
		var state = get_state();
		if(state.show_search){
			e.stopPropagation();
			pop_state();
		}
	});

	//When clicking on the close button, hide the search list
	$('#search-results').on('click', '.close-button', function(e){
		//show_search(false);
		e.stopPropagation();
		pop_state();
	});


	var last_search = '';
	var search_timer = null;
	var search_ajax = null;


	function update_search()
	{
		//var q = $('#search-input').val();
		var state = get_state();
		var q = state.searchInput;
		if(!q)q = '';

		var notEnoughInput = q.length < 3 && !q.match(/^\d+$/);

		$('#search-help').toggle(notEnoughInput);

		//Ignore if already updated for this value
		if(q == last_search)return;
		last_search = q;

		//Cancel current request 
		if(search_ajax){
			search_ajax.abort();
			search_ajax = null;
		}

		//If less than 3 search characters, and not a number, hide text
		if(notEnoughInput){
			$('#search-content').html('');
			return;
		}

		//Get search results
		search_ajax = $.getJSON(search_api_url+'?q='+encodeURIComponent(q), function(data){
			$('#search-content').html(data.html);//.css('display', 'block');
			lazyLoadInner(true); 
			search_ajax = null;
		});
	}

	//Handle up/down/escape/enter key presses
	$('#search-input').keydown(function(e){
		if(e.which == 27){ //escape
			//show_search(false);
			pop_state();
		}
		else if(e.which == 38 || e.which == 40){
			var items = $('#search-results .content').find('.page-item, .category, .more');
			var selected = $('#search-content .selected');
			var index = items.index(selected);
			selected.removeClass('selected');
			if(e.which == 40){
				if(index == -1 || index == items.length-1)index = 0;
				else index++;
			}
			else{
				if(index == -1 || index == 0)index = items.length-1;
				else index--;
			}
			items.eq(index).addClass('selected');
			e.preventDefault();
		}
		else if(e.which == 13){
			var selected = $('#search-content .selected');
			if(selected.length){
				//e.preventDefault();
				//window.location.href = selected.find('a').attr('href');
				selected.find('a').click();
			}
		}
		return;
	});

	/*
	//Make sure that search-form submissions, are sent to parent to handle if in app?
	$('#search-form').submit(function(e){
		e.preventDefault();
		e.stopPropagation();
		var form = $('#search-form');
		alert(form.prop('action')+'?'+form.serialize());
	});
	*/

	//When clicking the clear button, clear the search
	$('#search-button').on('click', function(){
		
		$('#search-form').submit();
		/*
		$('#search-input').val('');	
		var state = get_state();
		state.searchInput = '';
		replace_state(state);
		*/


	});

	//On changing text, update the search
	$('#search-input').on('change keyup paste', function(){
		var state = get_state();
		state.searchInput = $('#search-input').val();
		replace_state(state);
	});
}


function user_button_init()
{
	function show_user_menu(show)
	{
		var menu = $('#user-menu');
		var content = menu.find('.content');

		if(show){
			//$('#user-menu').removeClass('hidden');

			animate(function(f){

				if(this.first)
				{
					menu.removeClass('hidden');
				}

				content.css('opacity', f(0, 1));
				content.css('transform', 'scale('+f(0.8, 1)+')');

			}, 200);


		}
		else{

			animate(function(f){

				content.css('opacity', f(1, 0));

				if(this.last)
				{
					$('#user-menu').addClass('hidden');
				}

			}, 200);

			//animate out?

			//$('#user-menu').addClass('hidden');
		}
	}

	$('#user-button').on('click', function(e){
		if($('#user-menu').hasClass('hidden'))
			show_user_menu(true);
		else
			show_user_menu(false);
	});

	$(document).on('click', function(e){
		if($(e.target).closest('#user-button').length != 0)return;
		show_user_menu(false);
	});

	//When clicking on the close button
	$('#user-button').on('click', '.close-button', function(e){
		show_user_button(false);
	});

}

function category_button_init()
{

	//On state change
	on_state(function(state){

		//If need to show
		if(state.show_browse && !$('body').hasClass('browse-show')){

			//Display in initial state
			$('body').addClass('browse-showing');

			//Force reflow
			$('#categories')[0].offsetWidth;

			//Animate to final state
			$('body').addClass('browse-show');
		}

		//If need to hide
		else if(!state.show_browse && $('body').hasClass('browse-show')){

			$('body').removeClass('browse-show');

			setTimeout(function(){
				$('body').removeClass('browse-showing');
			}, 500);
		}
	});

	//On clicking the browse button, toggle the category list
	$('#browse-button').on('click', function(e){
		e.stopPropagation();
		var state = get_state();

		if(!state.show_browse){
			state.show_browse = true;
			if(state.show_search){
				state.show_search = false;
				replace_state(state);
			}
			else{
				push_state(state);	
			}
		}
		else{
			pop_state();
		}
	});

	//When clicking on the close button
	$('#categories').on('click', '.close-button', function(e){
		e.stopPropagation();

		//Pop the dialog
		pop_state();
	});

	//If clicking outside of active category menu
	$(document).on('click', function(e){
		if($(e.target).closest('#categories').length != 0)return;

		//If showing the browse dialog, pop it
		var state = get_state();
		if(state.show_browse){
			pop_state();
			e.stopPropagation();
		}

	});
}




//List of leaflet maps
var leafletMaps = [];
var leafletInitialised = false;


var leafletCloseButton = L.Control.extend({
 
	options: {
		position: 'top'+right
	},

	onAdd: function (map) {
		var container = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom close dialog-close');
		$('<a>').attr('href','#').appendTo(container).html(map_close);
		return container;
	},
});


var leafletRouteButton = L.Control.extend({
 
 	initialize: function (location) {
        
        //this.url = url;
        //this.html = html;
        this.location = location;
    },

	options: {
		position: 'bottom'+right
		//position: 'bottomright' 
	},

	onAdd: function (map) {

		var stuff = L.DomUtil.create('div', 'leaflet-bar leaflet-control-custom route');
		//var google_url = "http://maps."+google_domain+"/maps?"+"saddr=My+Location&daddr="+this.location.lat+','+this.location.lng;
		var google_url = "http://maps."+google_domain+"/maps?&q="+this.location.lat+"+"+this.location.lng+"&ll="+this.location.lat+"+"+this.location.lng;

		var waze_url = "http://waze.to/?ll="+this.location.lat+','+this.location.lng+"&navigate=yes";

		var waze_url_desktop = "https://www.waze.com/livemap?zoom=17&lat="+this.location.lat+"&lon="+this.location.lng;

		//If not mobile, use desktop version
		var is_mobile = (/android|iphone|ipad|ipod/i.test(navigator.userAgent));
		var waze_class = '';

		//If mobile, then use waze app, with website fallback
		var waze_html = '<a class="altclick" onclick="altclicktest(this);" data-alt="'+waze_url_desktop+'" href="'+waze_url+'" title="">'+map_waze+'</a>';

		//If not mobile, then use waze website
		if(!is_mobile) {
			waze_html = '<a href="'+waze_url_desktop+'" title="">'+map_waze+'</a>';
		}

		$(stuff).append('<a target="_blank" href="'+google_url+'" title="">'+map_google+'</a>'+waze_html);

		var test = $('<div><div class="route-title">'+map_route+'</div></div>').append(stuff);
		return test[0];
	},
});

function altclicktest(el)
{
	//alert($(el).data('alt'));

	//After 250ms
	var startTime = (new Date()).getTime();
	setTimeout(function(){

		//If more than 1 seconds elapsed, then ignore
		var endTime = (new Date()).getTime();
		if(endTime - startTime > 1000)return;

		//alert(endTime - startTime);

		//If the page is not visible (running in background), then ignore
		if(document.visibilityState2 != 'visible')return;

		//alert(document.visibilityState2);

		//Launch alternative url
		var url = $(el).data('alt');
		window.open(url, '_blank');

	}, 250);
}


function leaflet_init()
{

	//If first call of this function
	if(!leafletInitialised){
		leafletInitialised = true;

		//Set image path
		//L.Icon.Default.imagePath = '/image/';
		L.Icon.Default.imagePath = 'https://modiinapp.com/image/';

		//And have them call this...
		$(window).resize(leaflet_init);


		//Hack for when visibility state not supported
		//if(!('visibilityState' in document)){
			window.visibilityState2 = 'visible';
			$(window).on('focus',function(){
				document.visibilityState2 = 'visible';
			});
			$(window).on('blur',function(){
				document.visibilityState2 = 'hidden';
			});
		//}
		
	}

	//Have all maps resize now
	for(var i=0; i<leafletMaps.length; ++i){
		leafletMaps[i].invalidateSize(false);
	}


	var boundary_large = [34.4817722662228,31.1758782506608,35.3030268951078,32.2141324432038];

	var boundary_small = [34.7869045201696,31.7851549669859,35.0633828541339,32.0324680965554];

	//For each map container
	var maps = $('.leaflet');
	for(var m=0; m<maps.length; ++m){

		(function(){

			var mapDiv = maps[m];

			//If map already created, then ignore
			if($(mapDiv).children().length)return;

			//If container not visible, then ignore
			if(!$(mapDiv).is(':visible'))return;

			//Create leaflet map, using large bounds
			var map = L.map(mapDiv, {zoomControl:false, attributionControl:false});

			var map_width = $(mapDiv).width();

			//Always use touch styling (larger buttons)
			$(mapDiv).addClass('leaflet-touch');

			//Get if minimap or fullmap
			var fullmap = $(mapDiv).closest('.full-map-container').length != 0 || $(mapDiv).hasClass('full-map');

			//map-show-icons
			var show_icons = $(mapDiv).closest('.map-show-icons').length != 0;

			//Add out own layer
			var tilepath_background = "https://tiles.modiinapp.com/background/{z}/{x}/{y}.png";
			//var tilepath_background = "https://tiles.modiinapp.com/background2/{z}/{x}/{y}.png";
			//var tilepath_background = "http://192.168.99.100:32790/styles/rob-bright-background/{z}/{x}/{y}.png";
			
			var tilepath_lang = "https://tiles.modiinapp.com/"+lang+"/{z}/{x}/{y}.png";
			//var tilepath_lang = "https://tiles.modiinapp.com/"+lang+"2/{z}/{x}/{y}.png";
			//var tilepath_lang = "http://192.168.99.100:32790/styles/rob-bright-en/{z}/{x}/{y}.png";
			var tilesSize = 256;

			//var tilepath_background = "//tiles.modiinapp.com/512/background/{z}/{x}/{y}.png";
			//var tilepath_lang = "//tiles.modiinapp.com/512/"+lang+"/{z}/{x}/{y}.png";
			//var tilesSize = 512;

			//Add background layer
			var tile_layer = L.tileLayer.fallback(tilepath_background, {
				minZoom: 4, 
				maxZoom: 18, 
				maxNativeZoom: 18,
				updateWhenIdle: false,
				attribution: fullmap?osm_attribution:'',
				tileSize:tilesSize,
			});
			map.addLayer(tile_layer);

			//Add language layer
			var text_layer = new L.TileLayer(tilepath_lang, {
				minZoom: 4, 
				maxZoom: 18, 
				maxNativeZoom: 18,
				updateWhenIdle: false,
				attribution: fullmap?osm_attribution:'',
				tileSize:tilesSize,
			});
			map.addLayer(text_layer);



/*
			//setInterval(function(){
			map.on('move', function(){

				if(text_layer2._container){
					text_layer2._container.style['opacity'] = 0.5;
					text_layer2._container.style['filter'] = 'blur(2px)';
				}

				//Get current size and offset
				var transform = map._panes.mapPane.style.transform;
				var offset = transform.match(/translate3d\((-?\d+)px,\s*(-?\d+)px,\s*(-?\d+)px\)/);
				var size = map._size;

				if(text_layer._container){

					//Set the map scale
					text_layer._container.style['transform'] = 'scale(1.10)';

					//Set the transform origin
					text_layer._container.style['transform-origin'] = 
						Math.round(size.x/2-parseFloat(offset[1],10))+'px'+
						' '+
						Math.round(size.y/2-parseFloat(offset[2]))+'px';
				}
			});
			//}, 10);
*/

			function fix_leaflet_tile_unload(map, layer)
			{
				//4 times a second...
				setInterval(function(){

					//Ignore if currently zooming or loading
					if(map._animatingZoom || layer.isLoading()){
						return;
					}

					//Remove all tiles not at current zoom level
					var tiles = layer._tiles;
					for(var key in tiles){
						if(tiles[key].coords.z != layer._tileZoom){
							layer._removeTile(key);
						}
					}

				}, 250)
			}
			fix_leaflet_tile_unload(map, text_layer);


			//Manually add zoom control, and attribution
			map.addControl(L.control.zoom({'position':'top'+left}));
			map.addControl(L.control.attribution({'position':'bottom'+right, 'prefix':''}));

			//If there is central position (i.e. this is a single marker map)
			var hasCentre = false;
			if(window.map_location){
				hasCentre = true;

				//Center map on this location
				map.setView([map_location.lat, map_location.lng], fullmap?map_location.zoom:Math.min(map_location.zoom, 16));

				//Add marker
				var marker = L.marker(new L.LatLng(map_location.lat,map_location.lng));
				marker.addTo(map);

				//If this is full screen map, then add route button
				if(fullmap){
					map.addControl(new leafletRouteButton(L.latLng(map_location.lat, map_location.lng)));
				}
			}

			//If there are multiple positions
			else{

				//Set view to center of modiin initially
				map.setView([31.9000, 35.0076], 18);			
			}

//return;


			var state = get_state();
			var history_pos = false;
			var map_state = state.map;
			if(map_state){
				history_pos = true;
				map.setView([map_state.lat, map_state.lng], map_state.zoom, {animate:false});
			}


			//If there are markers to display (category view)
			if(!hasCentre){

				//Download from server				
		  		$.getJSON(location_url, function(page_locations){

					//Get initial bounds of map (at default location)
					var bounds = map.getBounds();

					//Create marker cluster
					var markers = L.markerClusterGroup({
						spiderfyDistanceMultiplier:2,
						maxClusterRadius: 50
					});

					markers.on('animationend', function(){
						lazyLoadImages();
					});
//alert(fullmap);
//alert(JSON.stringify(page_locations));
					//For each item
					for(var i=0; i<page_locations.length; ++i){
						var page_location = page_locations[i];
						var id = page_location.id;
						var lat = page_location.lat;
						var lng = page_location.lng;
						var image = page_location.image;
						var color = page_location.color;

						//Extend boundary to include marker
						var latlng = new L.LatLng(lat,lng);
						bounds.extend(latlng);

						//Create marker, with thumbnail if there is one
						var markerInfo = {};
						if(image && show_icons){
							//var html = '<img src="http://image.joelyaron.com/'+image+'_128_128_crop.jpg" style="background-color:#'+color+';"  />';
							var html = '<div data-src="https://image.modiinapp.com/'+image+'_128_128_crop.jpg" style="background-color:#'+color+';width:64px;height:64px;outline:1px solid rgba(0,0,0,0.15);    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);" class="img loading" ><img style="width:64px;height:64px;" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" class="jsonly"></div>';
							//alert(html);
							markerInfo.icon = L.divIcon({
								className: "map-icon",
							    html: html,
							    iconSize:     [66, 66], // size of the icon
							    iconAnchor:   [33, 33], // point of the icon which will correspond to marker's location
							    popupAnchor:  [0, -33] // point from which the popup should open relative to the iconAnchor
							});

							//alert(html);
						}

						//Create marker and add to group
						var marker = L.marker(latlng, markerInfo);
						marker.page_id = id;
						markers.addLayer(marker);

						//When the marker is clicked on
						marker.on('click', function(e){
							var marker = this;
							var id = marker.page_id;

							//If the marker already has a popup attached, then ignore (it will be used automatically)
							if(marker.getPopup()){
								return;
							}

							$(marker._icon).addClass('loading');

							//Download snippet
							var url = page_popup_url.replace(':id', id);
							$.get(url, function(html){

								$(marker._icon).removeClass('loading');


								var popup_width = Math.min(map_width-32, 295);
								
								//Create popup
								var popup = L.popup({
									//minWidth: 295,
									//maxWidth: 295,
									minWidth: popup_width,
									maxWidth: popup_width,

									autoPanPaddingTopLeft: L.point(10, 90),
									autoPanPaddingBottomRight: L.point(10, 10),
									closeOnClick: false,
									closeButton: false
								});
								popup.setContent(html);

								//Attach popup to marker, and open
								marker.bindPopup(popup);
								marker.openPopup();

							});

						});
					}


					//Instantly update boundary of map to include all markers, with padding to leave room for large icons
					if(!history_pos){
						map.fitBounds(bounds, {
							pan:{animate:false},
							zoom:{animate:false},
							padding:L.point(30, 30)
						});
					}

					//Add marker cluster to map
					map.addLayer(markers);

					//On opening a popup, lazyload images
					map.on('popupopen', function(e) {
						lazyLoadImages();
						setTimeout(function(){
							lazyLoadImages();
						}, 0);
					});

					//When clicking on the map, close any popups
					map.on('click', function(e){
						map.closePopup();
					});

					lazyLoadImages();
		  		});
			}

			//If this is an interactive map
			if(fullmap){

				//Add a close button
				map.addControl(new leafletCloseButton());
			}


			//On moving the map, store position
			map.on('moveend', function(e) {
				lazyLoadImages();
				var state = get_state();
				state.map = {
					lat:map.getCenter().lat,
					lng:map.getCenter().lng,
					zoom:map.getZoom()
				}
				replace_state(state);
			});
			

			//On dragging, close any open popups
			map.on('dragstart', function(e){
				map.closePopup();
			});
			
			//While dragging, lazy load images
			map.on('dragend', function(e){
				lazyLoadImages();
			});

			map.on('zoomstart', function(e){
				map.closePopup();
			});

			//Add map to global list
			leafletMaps.push(map);

		})();
	}

}

function calendar_init()
{
	$('body').on('click', '.calendar .next.active', function(){
		$(this).closest('.month').removeClass('current').next('.month').addClass('current');
	});
	$('body').on('click', '.calendar .prev.active', function(){
		$(this).closest('.month').removeClass('current').prev('.month').addClass('current');
	});
}



function alphabetical_init()
{
	$('body').on('click', '.alphabetical', function(){

		//Get all page items, and sort by title
		$('.categories-block .page-item').sort(function(a,b){
			return $(a).find('.title').text().localeCompare($(b).find('.title').text());
		})

		//Then add them back to the list in this order
		.appendTo('.categories-block');

		//Lazy load images
		lazyLoadImages();
	});
}

function nearest_init()
{

	if(!navigator.geolocation){
		//Hide nearest?
		return;
	}


	function measure(lat1, lon1, lat2, lon2){  // generally used geo measurement function
	    var R = 6378.137; // Radius of earth in KM
	    var dLat = (lat2 - lat1) * Math.PI / 180;
	    var dLon = (lon2 - lon1) * Math.PI / 180;
	    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
	    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
	    Math.sin(dLon/2) * Math.sin(dLon/2);
	    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
	    var d = R * c;
	    return d * 1000; // meters
	}


	
	$('body').on('click', '.nearest', function(){

		//Get current location within 5 seconds
		var options = {
			enableHighAccuracy: true,
			timeout: 5000,
			maximumAge: 0
		}
		navigator.geolocation.getCurrentPosition(function(pos){

			//If succesfull, then calc distance to each item
			var coords = pos.coords;
			$('.categories-block .page-item').each(function(){
				var $this = $(this);
				var dist = measure($this.data('lat'), $this.data('lng'), coords.latitude, coords.longitude);

				$this.data('dist', dist);
				$this.find('.distance').text((Math.round(dist/100)/10)+'km');
			})

			//Then sort them by distance
			.sort(function(a,b){
				return $(a).data('dist') - $(b).data('dist');
			})

			//Then add them back to the list in this order
			.appendTo('.categories-block');

			//Lazy load images
			lazyLoadImages();

		}, function(err){
			alert('Error');
		}, options);

	});
}

function paging_init()
{
	function load_page(loader)
	{
		var url = $(loader).data('href');
		$(loader).data('href', '').addClass('loading');
		$.get(url, function(html){
			$(loader).replaceWith(html);
			lazyLoadInner(true);
			//check_page();
		});
	}

	$('body').on('click', '.page-loader', function(){
		load_page(this);
	});

/*
	function check_page()
	{
		return 0;

		//Get window height
		var windowHeight = (window.innerHeight || document.documentElement.clientHeight);

		//For each loader
		$('.page-loader').each(function(){
			var loader = this;

			//If more than a screen away, or already loading, then ignore
			var rect = loader.getBoundingClientRect();	
			var url = $(loader).data('href');
			if(rect.top > (windowHeight*2))return;
			if(!url)return;

			load_page(loader);

		});
	}
*/
	//Check for page changes on scroll or resize
	$(window).on('scroll resize', function(){
		if(ignore_scroll)return;
		//check_page();
	});
}

function when_not_prerender(callback)
{
	//If page not prerendering, call now
	if(document.visibilityState != 'prerender'){
		return callback();
	}

	//Otherwise call when next not prerendering
	var interval = setInterval(function(){
		if(document.visibilityState != 'prerender'){
			clearInterval(interval);
			callback();
		}
	}, 100);
}


var analytics_id = null;

function send_analytics(info, next)
{
	//When not prerendering
	when_not_prerender(function(){

		//Add standard bits
		info._token = csrf;
		info.platform = app_mode()?'app':'web'; 
		info.lang = lang;

		//Send analytics
		$.ajax({
			url:analytics_url,
			type:'POST',
			method:'POST', //added
			data:info,
			headers:{'X-CSRF-TOKEN': csrf},
			dataType:'json'
		}).done(function(info){

			if(next)next(info);
			
			if(info.status == 'ok'){
				//alert(info.status);
				//alert(info.id);

				//Store analytics id
				analytics_id = info.id;			

				//Begin processing banne views
				//check_banner_views();

				//Monkey patch old lazy load function, so it also checks banner views
				var lazyLoadInnerOld = lazyLoadInner;
				lazyLoadInner = function(instant){
					lazyLoadInnerOld(instant);
					check_banner_views();
				}

				//Run now
				check_banner_views();
			}
		});
	});
}

function send_banner_analytics(){
	//does nothing, but called from layout
}

function check_banner_views()
{
	//Ignore if no analytics id to assign views
	if(!analytics_id)return;

	//Get window size
	var windowHeight = (window.innerHeight || document.documentElement.clientHeight);
	var windowWidth = (window.innerWidth || document.documentElement.clientWidth);
	var parentScrollY = 0;

	//List of unviewed banners that have been seen
	var visible = [];
	var visible_loaders = [];

	if(app_mode()){

		//For each unclicked page-loader
		var loaders = document.querySelectorAll('.page-loader');
		for(var i=0; i<loaders.length; ++i){
			var loader = loaders[i];

			//Get position relative to the viewport
	        var rect = loader.getBoundingClientRect();

	        //If no dimensions set, then not positioned in dom (so ignore)
	        if(!(rect.top||rect.bottom||rect.left||rect.right))continue;

	        //Calculate number of pixels away from the screen it is
			var distance = Math.min(
				(windowHeight-(rect.top-parentScrollY))/windowHeight, 
				(rect.bottom-parentScrollY)/windowHeight, 
				(windowWidth-rect.left)/windowWidth, 
				rect.right/windowWidth
			);

			//If visible on screen, then add to list
			if(distance > -1){
				visible_loaders.push([loader, loader.getAttribute('data-href')]);
			}
		}
	}

/*
function load_page(loader)
	{
		var url = $(loader).data('href');
		$(loader).data('href', '').addClass('loading');
		$.get(url, function(html){
			$(loader).replaceWith(html);
			lazyLoadInner(true);
			check_page();
		});
	}

	$('body').on('click', '.page-loader', function(){
		load_page(this);
	});
*/


	//For each unviewed banner
	var banners = document.querySelectorAll('.banner-unviewed');
	for(var i=0; i<banners.length; ++i){
		var banner = banners[i];

		//Get position relative to the viewport
        var rect = banner.getBoundingClientRect();

        //If no dimensions set, then not positioned in dom (so ignore)
        if(!(rect.top||rect.bottom||rect.left||rect.right))continue;

        //Calculate number of pixels away from the screen it is
		var distance = Math.min(
			(windowHeight-(rect.top-parentScrollY))/windowHeight, 
			(rect.bottom-parentScrollY)/windowHeight, 
			(windowWidth-rect.left)/windowWidth, 
			rect.right/windowWidth
		);

		//If visible on screen, then add to list
		if(distance > 0){
			visible.push([banner, banner.getAttribute('data-banner-id'), banner.getAttribute('data-banner-value')]);
		}
	};

	//For each loader, do auto load
	for(var i=0; i<visible_loaders.length; ++i){
		var loader = visible_loaders[i];
		var url = loader[1];
		$(loader[0]).data('href', '').addClass('loading');
		$.get(url, function(html){
			$(loader[0]).replaceWith(html);
			lazyLoadInner(true);
			//check_page();
		});

	}

	console.log('Logging banner views', visible);

	//Stop if no images to load
	if(visible.length == 0) return;

	//Flag all as viewed
	for(var i=0; i<visible.length; ++i){
		visible[i][0].className = 'banner-item';
	}

	//Build analytics data
	var info = {
		_token:csrf,
		view_id:analytics_id,
		banners:[],
		banners_free:[]
	}
	for(var i=0; i<visible.length; ++i){
		if(visible[i][2] == 0){
			info.banners_free.push(visible[i][1]);
		}
		else{
			info.banners.push(visible[i][1]);
		}
	}

	//Send it
	$.ajax({
		url:analytics_banner_view_url,
		type:'POST',
		method:'POST', //added
		data:info,
		headers:{'X-CSRF-TOKEN': csrf},
		dataType:'json'
	}).done(function(info){
		
		if(info.status == 'ok'){

			//alert('sent banners');
		}
	});

}


function url_click_init()
{
	//When clicking on a link
	$('body').on('click', 'a', function(){

		//Ignore if no business-id associated
		var id = $(this).data('business-id');
		if(!id)return;

		//Send analytics
		send_analytics({
			type:'page_url', 
			business_id:id, 
			url:$(this).attr('href')
		});

	});


	//When clicking on a phone-expand	
	$('body').on('click', '.phone-expand', function(e){

		//Expand
		$('.phone-full').css('display', 'inline-block');
		$('.phone-expand, .phone-partial').css('display', 'none');

		//Prevent link from being triggered
		e.preventDefault();
	});

}


function state_init()
{
	var state_callbacks = [];

	var current_state = {};

	window.on_state = function(callback)
	{
		state_callbacks.push(callback)
	}
	window.apply_state = function(state, instant)
	{
		current_state = state;
		for(var i=0; i<state_callbacks.length; ++i){
			state_callbacks[i](current_state, instant);
		}
		lazyLoadImages();
		leaflet_init();
	}
	window.get_state = function()
	{
		return JSON.parse(JSON.stringify(current_state));
	}

	if(window.history && window.history.pushState)
	{
		window.push_state = function(state)
		{
			history.pushState(state, "", "");
			apply_state(state);
		}
		window.replace_state = function(state, instant)
		{
			history.replaceState(state, "", "");
			apply_state(state, instant);
		}
		window.pop_state = function()
		{
			history.back();
		}
		$(window).on('popstate', function(e)
		{
			apply_state(e.originalEvent.state);
		});
	}
	else
	{
		var state_stack = [];

		window.push_state = function(state)
		{
			state_stack.push(state);
			apply_state(state);
		}
		window.replace_state = function(state, instant)
		{
			state_stack[state_stack.length-1] = state;
			apply_state(state, instant);
		}
		window.pop_state = function()
		{
			state_stack.pop();
			apply_state(state_stack[state_stack.length-1]);
		}
	}


}



/*
var animate = (function(){

	var requestAnimFrame = 
		window.requestAnimationFrame || 
  		window.webkitRequestAnimationFrame || 
  		window.mozRequestAnimationFrame || 
  		function(callback){ 
  			window.setTimeout(function(){
	  			var date = new Date();
	  			callback(date.getTime());
	  		}, 15);
	  	};

	var easingFunctions = {
		linear: function (t) { return t },
		easeInQuad: function (t) { return t*t },
		easeOutQuad: function (t) { return t*(2-t) },
		easeInOutQuad: function (t) { return t<.5 ? 2*t*t : -1+(4-2*t)*t },
		easeInCubic: function (t) { return t*t*t },
		easeOutCubic: function (t) { return (--t)*t*t+1 },
		easeInOutCubic: function (t) { return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1 },
		easeInQuart: function (t) { return t*t*t*t },
		easeOutQuart: function (t) { return 1-(--t)*t*t*t },
		easeInOutQuart: function (t) { return t<.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t },
		easeInQuint: function (t) { return t*t*t*t*t },
		easeOutQuint: function (t) { return 1+(--t)*t*t*t*t },
		easeInOutQuint: function (t) { return t<.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t }
	}

	return function(callback, duration, easing)
	{
		var startTime = 0;
		var currentTime = 0;
		var easingFunc = easingFunctions[easing] || easingFunctions.linear;

		function animate_inner(){

			//Get elapsed time
			var elapsed = (currentTime-startTime) / duration;
			if(elapsed > 1)elapsed = 1;

			//If not the first frame, then callback
			if(elapsed != 0){
			    //callback(easingFunc(elapsed));
			    var eased = easingFunc(elapsed);
			    callback(function(from, to){
			    	return from+(to-from)*eased;
			    });
			}

			//If not finished, get next frame
			if(elapsed != 1){
		      	requestAnimFrame(function(timestamp){
		      		if(startTime == 0)startTime = timestamp;
		      		currentTime = timestamp;
		      		animate_inner();			
		      	});
			}
		}
		animate_inner();
	}

})();
*/


window['animate'] = (function(){

	var requestAnimFrame = 
		window.requestAnimationFrame || 
  		window.webkitRequestAnimationFrame || 
  		window.mozRequestAnimationFrame || 
  		function(callback){ 
  			window.setTimeout(function(){
	  			var date = new Date();
	  			callback(date.getTime());
	  		}, 15);
	  	};

	var easingFunctions = {
		'linear': function (t) { return t },
		'easeInQuad': function (t) { return t*t },
		'easeOutQuad': function (t) { return t*(2-t) },
		'easeInOutQuad': function (t) { return t<.5 ? 2*t*t : -1+(4-2*t)*t },
		'easeInCubic': function (t) { return t*t*t },
		'easeOutCubic': function (t) { return (--t)*t*t+1 },
		'easeInOutCubic': function (t) { return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1 },
		'easeInQuart': function (t) { return t*t*t*t },
		'easeOutQuart': function (t) { return 1-(--t)*t*t*t },
		'easeInOutQuart': function (t) { return t<.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t },
		'easeInQuint': function (t) { return t*t*t*t*t },
		'easeOutQuint': function (t) { return 1+(--t)*t*t*t*t },
		'easeInOutQuint': function (t) { return t<.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t },
		'easeOutElastic': function(t) {var p = 0.3; return Math.pow(2,-10*t) * Math.sin((t-p/4)*(2*Math.PI)/p) + 1;}
	}

	return function(info, arg2)
	{
		if(typeof info === 'function'){
			info = {
				each:info,
				duration:arg2
			}
		}

		//State
		var startTime = 0;
		var currentTime = 0;
		var completed = false;
		var showedFirst = false;

		//Options
		var delay = info['delay'] || 0;
		var firstcallback = info['first'];
		var duration = info['duration'] || 300;
		var easingFunc = easingFunctions[info['easing']] || easingFunctions['easeInOutQuad'];
		var progresscallback = info['each'];
		var endcallback = info['last'];
		var startpaused = info['paused'];

		//Next animate object
		var nextanimate = null;

		function animate_inner(timestamp){

			//Ignore if already completed
			if(completed)return;

			//Get time
      		if(startTime == 0)startTime = timestamp;
      		currentTime = timestamp;

			//Get elapsed time
			var elapsed = (currentTime-startTime-delay) / duration;
			if(elapsed > 1)elapsed = 1;

			//If passed animation start time
		    if(elapsed >= 0){

		    	//If not yet showed first frame
		    	if(!showedFirst){
		    		showedFirst = true;

					//Run first callback
					if(firstcallback){
						firstcallback();
						firstcallback = null;
					}

					//Reinititialise start time, so that next frame will again show first position (in case of jump)
					startTime = 0;
		    	}

		    	var eased = easingFunc(elapsed);
		    	var testob = {
		    		progresscallback:progresscallback,
		    		progress:elapsed,
		    		eased:easingFunc(elapsed),
		    		tween:function(from, to){
				    	return from+(to-from)*eased;
				    },
		    		first:startTime==0,
		    		last:elapsed==1
		    	}
		    	testob.progresscallback(testob.tween);

		    	/*
		    	//Run the progress callback
			    var eased = easingFunc(elapsed);
			    progresscallback(function(from, to){
			    	return from+(to-from)*eased;
			    });
			    */
			}

			//If not finished, get next frame
			if(elapsed != 1){
		      	requestAnimFrame(animate_inner);
			}
			//Otherwise run the final callback
			else{
				if(endcallback)endcallback();
				completed = true;
				if(nextanimate){
					nextanimate['start']();
				}
			}
		}

		if(!startpaused){
			requestAnimFrame(animate_inner);
		}

		return {

			//Chain
			'animate':function(info){
				info.paused = true;
				nextanimate = animate(info);
				return nextanimate;
			},

			'stopped':function(){
				return completed;
			},

			//Control
			'finish':function(){
				if(completed)return;

				//Complete this animation (calling all callbacks) and recurse
				if(firstcallback)firstcallback();
				progresscallback(function(a,b){return b;});
				if(endcallback)endcallback();
				completed = true;
				if(nextanimate)nextanimate['finish']();
			},
			'stop':function(){
				completed = true;
			},
			'start':function(){
				//animate_inner();

				requestAnimFrame(animate_inner);
			}
		}
	}

})();


function tabs_init()
{
	on_state(function(state, instant){


		//TODO: look at tab order, so that the '-start' can be '-start-right' or '-start-left'
		//also, make the change a bit more generic? if possible? tie the tab name to the block name?

		//Should we make the page transitioning use classes assigned directly to the blocks, rather than body level?

		//This would simplify the logic here, and reduce the css weirdness, but make t

		//If there is a page to be selected
		if(state && state.selected_page){

			//Get page/tab to be made visible
			var appear = $('.'+state.selected_page+'-block');
			var appearTab = $('#'+state.selected_page+'-tab');

			//Get page/tab to make disappear
			var disappear = $('.block-fade-visible').not(appear);
			var disappearTab = $('.tab.selected');

			var behaviour = appearTab.data('behaviour');

			//If the tab hasn't changed, then do nothing
			if(appearTab.is(disappearTab)){
				return;
			}

			//Get animation classes to use (reversing start and end direction if need be)
			var start = 'block-fade-left';
			var end = 'block-fade-right';
			var visible = 'block-fade-visible';
			if(appearTab.index() > disappearTab.index()){
				var temp = end;
				end = start;
				start = temp;
			}

			if(!instant){
				//Make new one visible 
				appear.addClass(start);
				appearTab.addClass('selected');
				$('body')[0].offsetWidth;
				appear.addClass(visible).removeClass(start);

				//Make old one disappear 
				disappear.addClass(end).removeClass(visible);
				disappearTab.removeClass('selected');

				//Final state
				setTimeout(function(){
					disappear.removeClass(end);
				}, 400);
			} 
			else{

				//Just set dinal state directly
				appear.addClass(visible);
				appearTab.addClass('selected');
				disappear.removeClass(visible);
				disappearTab.removeClass('selected');
			}
			//alert($('.tabs-container').position().top - $('.tabs-container').height());

			if($('.tabs-container').length){

				//todo: if behaviour scroll-top, then put tabs at top, otherwise fully scroll to top

				var scrolly = Math.round($('.tabs-container').position().top - $('.tabs-container').height() - 1);
				var currentscrolly = $('#body-inner').scrollTop();
				if(behaviour != 'scroll-top'){
					scrolly = 0;
				}


				//if(scrolly < currentscrolly || behaviour == 'scroll-top'){
				if(scrolly != currentscrolly){
					//$('#body-inner').scrollTop(scrolly);
					/*
					setTimeout(function(){
						$('#body-inner').stop().animate({
							scrollTop:scrolly
						}, 350, 'swing');
					},350);
					*/

					//setTimeout(function(){
						//scrollTo(scrolly, function(){}, 300);
						var body_inner = $('#body-inner')[0];

						

						/*						
						animate(function(e){
							body_inner.scrollTop = e(currentscrolly, scrolly);
						}, 350, 'easeInOutQuad');
						*/

						
						//alert(1);
						animate({
							duration:350,
							each:function(f){
								body_inner.scrollTop = f(currentscrolly, scrolly);
							},
						});


						/*
						setTimeout(function(){
							var all_content = $('.all-content')[0];
							animate(function(e){
								all_content.style.transform = 'translateY('+e(0, currentscrolly-scrolly)+'px)';
							}, 350, 'easeInOutQuad');
						}
						*/


						//setTimeout(function(){
						//	ignore_scroll = false;
						//}, 400);
						//body_inner.scrollTop = scrolly;

					//}, 350);

					//var body_inner = $('#body-inner')[0];
					//body_inner.scrollTo({top:scrolly,left:0,behavior:'smooth'});
				}
			}

			//$('#body-inner').stop().animate({scrollTop:scrolly}, 350, 'swing');
		}


		return;

	});


	$('body').on('click', '.page-select', function(e){
		var state = get_state();
		state.selected_page = $(this).data('for');
		replace_state(state);
	});



	//Make tabs work
	$('body').on('click', '.tab-open', function(){
		var state = get_state();
		state.selected_page = $(this).attr('for');
		replace_state(state); //fails here
	});


}

function dialog_init()
{
	//Works for deals, maps

	on_state(function(state, instant){

		//For each dialog, open or close it to match state
		$('.dialog-container').each(function(){
			var dlg = $(this);
			var pushed_open = state && dlg.attr('id') == state.open_dialog;
			var is_open = dlg.hasClass('open');

			//If it is closed and should be open, open it
			if(pushed_open && !is_open){

				if(instant){
					dlg.addClass('opening open');
				}
				else{
					dlg.addClass('opening');
					dlg[0].offsetWidth;
					dlg.addClass('open');
					setTimeout(function(){
						lazyLoadImages();
						leaflet_init();
					}, 333);
				}

			}
			//If it is open, and should be closed, close it
			else if(!pushed_open && is_open){
				dlg.addClass('opening');
				dlg.removeClass('open');
				setTimeout(function(){
					dlg.removeClass('opening');
				}, 500);
			}
		});	

		/*
		//If there is a selected page, then update class list so that the new page is the only selected one
		var name = false;

		

		if(state.selected_page){
			name = 'selected-'+state.selected_page;
		}
		var classes = $('body').attr('class').split(/\s+/);
		var newClasses = [];
		for(var i = 0; i<classes.length; ++i){
			if(classes[i].indexOf('selected-') != 0){
				newClasses.push(classes[i]);
			}
		}
		if(name){
			//newClasses.push(name);
			newClasses.push(name+'-start');
		}

		//Assign the new class
		$('body').attr('class', newClasses.join(' '));
		//$('.deals-large')[0].offsetWidth;
		$('body')[0].offsetWidth;
		if(name){
			newClasses.push(name);
		}
		$('body').attr('class', newClasses.join(' '));
		*/



		//Run initialisation for maps, and lazy load images
		//leaflet_init();
		//lazyLoadImages();
	});



	$('body').on('click', '.dialog-open', function(){


		//If in the app and clicking on a map, then push map instead
		if($(this).data('for') == 'map-dialog' && app_mode()){
			//alert(map_page_url);

			function parent_message(info)
			{
				parent.postMessage(JSON.stringify(info), '*');
			}

			//Send message to parent
			parent_message({
				'type':'load_url',
				'display_url':map_page_url,
				'url':map_page_url,
				'title':$(this).attr('title')
			});

			return;
		}

		var state = get_state();
		if(state){
			state.open_dialog = $(this).data('for');
		}

		push_state(state);

		//Send analytics
		send_analytics({
			type:'page_deal',
			business_id:$(this).data('business-id'),
			business_offer_id:$(this).data('offer-id')
		});

	});

	/*
	function close_dialog()
	{
		//If state was pushed, then pop it
		var state = get_state();
		if(state.pushed){
			pop_state();			
		}
		//Otherwise close dialog without popping state
		else{
			state.open_dialog = null;
			replace_state(state);
		}
	}
	*/


	/*
	$('body').on('touchstart', '.dialog-close', function(e){
		e.preventDefault();
		e.stopPropagation();
	});
	$('body').on('touchend', '.dialog-close', function(e){
		e.preventDefault();
		e.stopPropagation();
	});
*/

	$('body').on('click', '.dialog-close', function(e){
		pop_state(); //good
		e.preventDefault();
		e.stopPropagation();
	});

	$('body').on('click', '.dialog-container', function(){
		pop_state();
	});


	$('body').on('click', '.dialog', function(e){
		e.stopPropagation();
	});
	$(document).keyup(function(e) {
		if(e.keyCode == 27){
			var state = get_state();
			if(state && state.open_dialog)
				pop_state();
		}
	});
}

function app_mode() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

function push_notifications_init()
{
	//navigator.serviceWorker.register("/firebase-messaging-sw.js");

	//return;
	function loadModule(module, callback) {
		var script = document.createElement('script');
		script.src = "https://www.gstatic.com/firebasejs/4.3.0/firebase-"+module+".js";
		script.async = true;
		script.onload = callback;
		script.onerror = function(){throw new Error();};
		document.head.appendChild(script);
	}

	//Load firebase library
	loadModule('app', function(){
		loadModule('messaging', function(){

			//Initialise firebase
			firebase.initializeApp({messagingSenderId: "422742139053"});
			var messaging = firebase.messaging();

			//Get token
			messaging.requestPermission()
			.then(function(){
				return messaging.getToken()			
			})
			.then(function(currentToken) {
				if (currentToken) {
					console.log('Current token', currentToken);
				} else {
					// Show permission request.
					console.log('No Instance ID token available. Request permission to generate one.');
				}
			})
			.catch(function(err) {
				console.log('Unable to get permission to notify.', err);
			});

			//Handle message
			messaging.onMessage(function(payload){
				var info = JSON.parse(payload.data.notification);
				var notificationTitle = info.title;
			    var notificationOptions = {
			        body: info.body,
			        icon: info.image
			    };
				if (!("Notification" in window)) {
			        console.log("This browser does not support system notifications");
			    }
			    else if (Notification.permission === "granted") {
			        var notification = new Notification(notificationTitle,notificationOptions);
			        notification.onclick = function(event) {
			            event.preventDefault();
			            window.open(info.url, '_blank');
			            notification.close();
			        }
			    }
			});
		});
	});

}

function login_init()
{
	if(app_mode())
	{
		$('.review-sign-in a').on('click', function(e){
			
			parent_message({'type':'login'});

			e.stopImmediatePropagation();
			e.preventDefault();
		});
	}
	//alert(1);
	
	//website  - site url = https://modiinapp.com/
	//app domains - modiinapp.com test.modiinapp.com

	//website - site url = http://localhost:8000/
	//app domains - localhost


	//window.location = login_url;
	
	/*
	$('#login-button').click(function(){
		//alert(1);

		return;

		var client_id = 1379402822358982;
		var redirect_uri = window.location.href;
		alert(redirect_uri);

		var login_url = "https://www.facebook.com/v4.0/dialog/oauth?client_id="+client_id+"&redirect_uri="+encodeURIComponent(redirect_uri)+"&state={state-param}";

		window.location = login_url;
	});
	*/
}

function review_init()
{
	var reviews_block = $('.page-reviews-block');

	reviews_block.on('click', '.review', function(e){
		if(!$(this).hasClass('review-expanded')){
			$(this).addClass('review-expanded');
		}
	});

	reviews_block.on('click', '.reviews-expand', function(e){
		$(this).closest('.reviews').addClass('reviews-expanded');
	});

	reviews_block.on('click', '.review-edit-button', function(e){
		var block = $(this).closest('.page-reviews-block');
		block.find('.review-preview-block').css('display', 'none');
		block.find('.review-edit-block').css('display', 'block');
	});

	reviews_block.on('click', '.review-delete-button', function(e){
		if(!confirm(recommendation_form_delete_confirm)){		
			e.preventDefault();
			e.stopPropagation();
		}
	});


	//When clicking a submit button, store the submit button action
	reviews_block.on('click', 'form input[type=submit]', function() {
        $(this).closest('form').find("input[name=action]").val($(this).attr('name'));
    });

	reviews_block.on('submit', 'form', function(e){
		var $this = $(this);

		e.preventDefault();
		e.stopPropagation();

		$.ajax({
			url:$this.attr('action'),
			type:'post',
			//dataType:'json',
			data:$this.closest('form').serialize(),
			success:function(data){

				$this.closest('.review-your').html(data);


				//Tell app to reload frames
				if(app_mode()){
					parent_message({'type':'invalidate_frames'});
				}


				//TODO: take returned html, and overwrite reviews-block

			}
		});


		//Build url
		//var url = $this.prop("action")+'?'+$this.serialize();

		//Send to app to handle
		//return nav_handle(url);
	});


	/*
	$('body').on('click', '.review-recommend-button', function(){
		$(this).closest('.review-entry')
			.removeClass('complaining')
			.addClass('recommending');
	});

	$('body').on('click', '.review-complain-button', function(){
		$(this).closest('.review-entry')
			.removeClass('recommending')
			.addClass('complaining');
	});*/
}

function favourite_button_init()
{
	$('.favourite-add-button').click(function(){

		//Get id
		var id = $(this).data('id');
		var $this = $(this);


		//add secret
		
		//var qs = parse_query_string(query);

		/*
		//Get url attributes
		var attribs = [];
		var query = window.location.search.substring(1);
		if(query){
			var params = query.split('&');
			for(var i=0; i<params.length; ++i){
				var param = params[i].split('=');
				attribs[param[0]] = decodeURIComponent(param[1]);
			}
		}


		var secret = query.secret;
		alert(secret);
		*/

		//Post this
		$.ajax({
			url:bookmark_add_url,
			type:'POST',
			method:'POST', //added
			data:jQuery.param({id:id}),
			headers:{'X-CSRF-TOKEN': csrf},
			//dataType:'json',
			success:function(){
				//console.log('success1');
				$this.parent().removeClass('favourite-no').addClass('favourite-yes');				
			}
		});
	});

	$('.favourite-remove-button').click(function(){

		//Get id
		var id = $(this).data('id');
		var $this = $(this);

		//Post this
		$.ajax({
			url:bookmark_remove_url,
			type:'POST',
			method:'POST', //added
			data:jQuery.param({id:id}),
			headers:{'X-CSRF-TOKEN': csrf},
			//dataType:'json',
			success:function(){
				//console.log('success2');
				$this.parent().removeClass('favourite-yes').addClass('favourite-no');				
			}
		});

	});
}

function edit_favourites_init()
{
	//import { Sortable, AutoScroll } from 'sortablejs';
	//Sortable.mount(new AutoScroll());

	var sortable = null;
	var initialised = false;

	function save_changes()
	{
		//alert('saving');

		//Get ids
		var ids = [];
		$('.reviews-page .page-item').each(function(){
			ids.push($(this).data('id'));
		});

		//Upload to server

		//Send to server
		$.ajax({
			url:bookmark_update_url,
			type:'POST',
			method:'POST', //added
			data:{'bookmarks':ids},
			headers:{'X-CSRF-TOKEN': csrf},
			dataType:'json'
		});

	}

	$('.reviews-page').on('click', '.delete-link', function(e){
		e.preventDefault();
		e.stopPropagation();

		//alert($(this).closest('.page-item').data('id'));
		if(confirm("Are you sure you want to remove this item?")){
			$(this).closest('.page-item-container').remove();
			save_changes();
		}
	});

	var el = $('.reviews-page .pages-block')[0];
	if(el){

		//Sortable.mount(new AutoScroll());
		var dragScroller = null;
		var dragTouchY = null;
		var dragTouchEvent = null;
		var dragTime = null;


		sortable = Sortable.create(el, {
			//'draggable':'page-item',
			'draggable':'.page-item-container',
			'forceFallback':true,
			'dragClass':'sortable-drag',
			'ghostClass':'sortable-ghost',
			'chosenClass': "sortable-chosen",
			'handle':".drag-handle",
			'scroll':false, //needed to not break ios
			//'onEnd':save_changes,
			//'scroll':true, //breaks ios...
			//'scroll':false
			//'scroll':$('#body-inner')[0],
			//'onMove':function(e, oe){
			//	alert(oe.clientY);
			//}
			'onStart':function(){

				var bodyInner = $('#body-inner');
				var screenY = bodyInner.scrollTop();
				var screenHeight = bodyInner.height();

				if(dragScroller){
					clearInterval(dragScroller);
					dragScroller = null;
				}

				dragTime = (new Date()).getTime();

				dragScroller = setInterval(function(){
					//screenY = bodyInner.scrollTop();
					if(dragTouchY === null)return;
					var now = (new Date()).getTime();
					var dir = dragTouchY-screenHeight*0.5;
					var elapsed = now-dragTime;
					dragTime = now;
					screenY += dir*elapsed/1000;
					bodyInner.scrollTop(screenY);

					//Sortable.active._onTouchMove(touchEvt);
					if(dragTouchEvent){
						Sortable.active._onTouchMove(dragTouchEvent);
					}
				},16);

			},
			'onEnd':function(){

				//Stop reacting
				if(dragScroller){
					clearInterval(dragScroller);
					dragScroller = null;
				}

				save_changes();
			}
		});

		//Keep track of touch position
		$(el).on('touchmove', function(e){
			//dragTouchY = e.originalEvent.touches[0].pageY;
			dragTouchY = e.originalEvent.touches[0].clientY;
			dragTouchEvent = e.originalEvent;
		});


		/*
		$('.reviews-page').addClass('editing');
		$('.reviews-page')[0].offsetHeight;
		$('.reviews-page').addClass('editing-complete');
		*/
	}
	

/*
	$('.reviews-page .edit-button-on').click(function(){

		if(!initialised){
			initialised = true;

			$('.reviews-page').on('click', '.delete-link', function(e){
				e.preventDefault();
				e.stopPropagation();

				//alert($(this).closest('.page-item').data('id'));
				if(confirm("Are you sure you want to remove this item?")){
					$(this).closest('.page-item').remove();
					save_changes();
				}
			});

			var el = $('.reviews-page .pages-block')[0];
			sortable = Sortable.create(el, {
				//'draggable':'page-item',
				'draggable':'.page-item',
				'forceFallback':true,
				'dragClass':'sortable-drag',
				'ghostClass':'sortable-ghost',
				'chosenClass': "sortable-chosen",
				'handle':".drag-handle",
				'onEnd':save_changes,
				'scroll':true
			});

		}

		$('.reviews-page').addClass('editing');
		$('.reviews-page')[0].offsetHeight;
		$('.reviews-page').addClass('editing-complete');
	});

	$('.reviews-page .edit-button-off').click(function(){
		$('.reviews-page').removeClass('editing');
		$('.reviews-page').removeClass('editing-complete');
	});

	*/
}


function createCookie(name, value, days) {
    var expires;

    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = encodeURIComponent(name) + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ')
            c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
            return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, "", -1);
}

function install_button_init()
{ 
	if(!readCookie("supress-install-prompt")){

		//On clicking the install prompt close button
		$('body').on('click', '.install-prompt-close', function(e){

			//Hide it
			$('.install-prompt-container').addClass('hidden');
			e.preventDefault();

			//Set cookie to prevent it being shown again for 7 days
			createCookie("supress-install-prompt", 1, 7);
		});

		//After 1 second
		setTimeout(function(){

			//Show the install prompt
			$('.install-prompt-container').removeClass('hidden');

			//But hide it after scrolling enough
			var top = $(window).scrollTop();
			$(window).scroll(function(){
				if(ignore_scroll)return;
				var top2 = $(window).scrollTop();
				if(Math.abs(top2-top) > 500){
					$('.install-prompt-container').addClass('hidden');
				}
			});

		},1000);
	}


}

function action_tabs_init()
{
	$('body').on('click', '.tab2-tab', function(){
		var index = $(this).index();
		var block = $(this).closest('.tab2-block');
		block.find('.tab2-tab-current').removeClass('tab2-tab-current');
		block.find('.tab2-page-current').removeClass('tab2-page-current');
		block.find('.tab2-tab').eq(index).addClass('tab2-tab-current');
		block.find('.tab2-page').eq(index).addClass('tab2-page-current');
	});
}

var deal_animate = null;

function deal_init()
{
	//TODO: should automatically expand if the id is there?

	$('body').on('click', '.deal-top', function(){
		//alert(1);

		var deal = $(this).closest('.deal');
		var bottom = deal.find('.deal-bottom');


		/*
		//Get height
		var height = deal.outerHeight();

		//Complete any existing animation
		if(deal_animate)deal_animate.finish();
		*/
		var expanded = deal.hasClass('expanded');

		//Change state
		if(!expanded){
			deal.addClass('expanded');
		}
		else{
			deal.removeClass('expanded');
		}



		if(!expanded){

			animate({
				duration:350,
				easing:'easeOutQuad',
				each:function(f){
					bottom.css('transform', 'translateX('+f(30, 0)+'px)');
					bottom.css('opacity', f(0, 1));
				},
				last:function(){
					bottom.css('transform', '');
					bottom.css('opacity', '');
				},
			});	
		}

		/*
		//Force reflow
		if(deal.length){
			deal[0].offsetLeft;
		}

		//Get new height
		var targetHeight = deal.outerHeight();

		//Animate between these two heights, then remove style
		deal_animate = animate({
			duration:350,
			each:function(f){
				deal.css('height', f(height, targetHeight)+'px');
			},
			last:function(){
				deal_animate = null;
				deal.css('height', '');
			},
		});
		*/
	});
}

/*
function sticky_init()
{
	var fixed = false;
	var container = $('.tabs-container');
	var tabs = $('.tabs');

	if(container.length < 1)return;

	$('.body-inner').on('scroll', function(){
		var needed = container.offset().top <= 54;
		if(needed == fixed)return;
		fixed = needed;
		tabs.toggleClass('fixed', fixed);
	});
}
*/

function sticky_init()
{
	//return;

	var fixed_top = false;
	var fixed_bottom = false;
	var container = $('.sticky-container');
	var height = container.height();
	if(container.length < 1)return;
	var tabs = container.children();
	var windowheight = $(window).height();
	var top_pad_height = $('.app-top-pad').height();
	var bottom_pad_height = 0;

	function scrollhandler()
	{
		//If flagged to temporarily ignore scrolling, then do nothing
		if(ignore_scroll)return;

		//Get screen position of sticky container
		var top = container.offset().top;

		//Test if sticky container is beneath top padding (and so needs to be fixed)
		var needed = top <= top_pad_height;

		//If not current status
		if(needed != fixed_top){
			fixed_top = needed;

			//Set to be top fixed
			tabs.toggleClass('fixed-top', fixed_top);
			if(needed == true){
				tabs.css('top', top_pad_height+'px');
			}
			else{
				tabs.css('top', '');	
			}
		}

		//Test if sticky container is below bottom padding
		needed = top > (windowheight - height);

		//If not current status
		if(needed != fixed_bottom){
			fixed_bottom = needed;

			//Set to be bottom fixed
			tabs.toggleClass('fixed-bottom', fixed_bottom)
			if(needed == true){
				tabs.css('bottom', bottom_pad_height+'px');
			}
			else{
				tabs.css('bottom', '');
			}
		}
	}

	$('.body-inner').on('scroll', scrollhandler);
	scrollhandler();
}

function white_gap_fix_init()
{
	//document.body.style['background-color'] = 'red';
	/*
	Doesn't seem to help...

	let isFormFieldActive = false;

    window.addEventListener('focusin', function focusInHack() {
      var elementName = document.activeElement.localName;
      if (['select', 'textarea', 'input'].includes(elementName)) {
        isFormFieldActive = true;
      }
    });

    window.addEventListener('focusout', function focusOutHack() {
      if (isFormFieldActive) {
        setTimeout(() => {
          window.scrollTo(0, document.documentElement.clientHeight);
        });
        isFormFieldActive = false;

	      //alert(1);
      }
    });
    */
}


function get_param(name, def){
	if(name=(new RegExp('[?&]'+encodeURIComponent(name)+'=([^&]*)')).exec(location.search))
		return decodeURIComponent(name[1]);
	return def;
}

function parent_message(info)
{
	parent.postMessage(JSON.stringify(info), '*');
}

function recommendations_init()
{
	var hash = '';
	if(window.location.hash){
		hash = window.location.hash.substring(1); 
	}

	if(hash){
		$('.review[data-id="'+hash+'"]').addClass('review-expanded');
	}
}

function hash_hack_init()
{
	return;

	var hash = '';
	if(window.location.hash){
		hash = window.location.hash.substring(1); 
	}

	var selected_page = null;
	if(hash == 'recommendations'){
		selected_page = 'reviews';
	}
	else if(hash == 'deals'){
		selected_page = 'deals';
	}                                                                                                                                 
	else if(hash == 'map'){
		selected_page = 'map';
	}

	if(selected_page && $('#'+selected_page+'-tab').length == 1){
		var state = get_state();
		state.selected_page = selected_page;
		replace_state(state); //fails here
	}
}

function init()
{
	//alert(1);
	if(app_mode()){		

		//Add padding
		$('.app-top-pad').css('height', get_param('top-padding', 0)+'px');
		$('.app-bottom-pad').css('height', get_param('bottom-padding', 0)+'px');

		//Make sure that inner scroll is saved/restored between reloads
		if(history.state && history.state.scrollPosition) {
			$("#body-inner").scrollTop(history.state.scrollPosition);
		}

		/*
		$("#body-inner").scroll(function(e) {
			var state = history.state || {};
			state.scrollPosition = $("#body-inner").scrollTop();
			history.replaceState(state, null, null);
		});
		*/
		//When closing this, save the scroll position
		$(window).on('beforeunload', function(){
			var state = history.state || {};
			state.scrollPosition = $("#body-inner").scrollTop();
			history.replaceState(state, null, null);			
		});

	}
	//$("#body-inner").scrollTop(1);
	//return;

	
	/*
	setTimeout(function(){
		history.replaceState({scrollPosition:1}, null, null);
	}, 100);
*/

	//setTimeout(function(){
	$('.layer-2 div').css('transform', 'scale(1)');
	$('.layer-3 div').css('transform', 'scale(1)');

	state_init();

	deal_init();

	lazy_load_init();  //needed

	dialog_init();

	tabs_init(); //needed


	login_init();

	review_init();

	category_button_init();

	user_button_init();

	search_bar_init();

	load_gallery(); //needed

	leaflet_init(); //needed
	
	calendar_init(); //needed

	paging_init(); //needed

	url_click_init(); //needed

	nearest_init();

	alphabetical_init();

	google_analytics();	//needed

	favourite_button_init(); //needed

	edit_favourites_init(); //needed

	install_button_init();

	action_tabs_init(); //needed

	recommendations_init();

	hash_hack_init();

	$('body').on('click', '.search-extra-prompt', function(e){
		$('.search-extra').css('display', 'block');
		$('.search-extra-prompt').css('display', 'none');
		lazyLoadImages();
	});

	//Set initial state!
	replace_state(window.history.state || window.initial_state || {}, true);


	//Remove ugly facebook login redirect hash appending
	if(window.location.href.indexOf('#_=_') > 0) {
		window.location = window.location.href.replace(/#.*/, '');
	}


	if(!app_mode()){

		//push_notifications_init();
	}

	//When clicking on a share, open it in a new window
	$('body').on('click', 'a', function(e){
		var $this = $(this);
		if($this.data('type') != 'window')return;
		if(!$this.hasClass('share'))return;

		//Don't allow any other processing
		e.preventDefault();
		e.stopPropagation();
		e.stopImmediatePropagation();

		//Open window
		window.open($this.attr('href'),'','width='+$this.data('width')+',height='+$this.data('height'));
	});

	//alert('before');

	if(app_mode()){

		white_gap_fix_init();

		//alert('app mode!');

		//Disable scrolling dialogs beyond limits, to avoid them scrolling the parent (particularly problematic for fake-fixed iOS dialogs)
		var y_old = -1;


		/*
		$('.body-inner').on('scroll', function(){
			var scrollTop = $('.body-inner').scrollTop();
			if(scrollTop < 0)scrollTop = 0;

			$('.cover-container').css('transform', 'translateY('+(scrollTop*0.3)+"px)");
		});
*/

		$('body').on('touchstart', '.dialog-container', function(e){
			var y_new = e.originalEvent.targetTouches[0].pageY;
			y_old = y_new;
		});

		$('body').on('touchmove', '.dialog-container', function(e){
			var y_new = e.originalEvent.targetTouches[0].pageY;
			var dir = y_new - y_old;
			y_old = y_new;
			if(dir > 0 && this.scrollTop <= 0){
				e.preventDefault();
			}
			else if(dir < 0 && this.scrollTop >= (this.scrollHeight - this.clientHeight)){
				e.preventDefault();
			}
		});



			/*
		$('.body-inner').on('scroll', function(e){

			var scrollTop = $('.body-inner').scrollTop();


		});
*/

		//On receiving messages from parent
		window.addEventListener('message', function messageListener(e){

			//Once received a scroll position
			var msg = JSON.parse(e.data);
			if(msg.type == 'scroll_pos'){

				//Store this info
				window.scroll_pos = msg;

				//Alter the position of all dialog-containers
				$('.dialog-container, .pswp').css({
					'position':'absolute',
					'right':'auto',
					'bottom':'auto',
					'top':scroll_pos.y,
					'left':scroll_pos.x,
					'height':scroll_pos.h,
					'width':scroll_pos.w
				});
			}

			if(msg.type == 'scroll_top'){
				window.scrollTo(0,0);
			}

			if(msg.type == 'geolocation'){

				window.geolocation = msg;
				//alert(JSON.stringify(geolocation));
			}

			if(msg.type == 'appearing'){
				//alert('Appearing!');

				$(window).trigger('appearing');
			}
		});


		function nav_handle(url, e)
		{
			//Append 'app=1' to use appified version of page
			//url += (url.indexOf('?')==-1)?'?':'&';
			//url += 'app=1';

			//Send message to parent
			parent_message({
				'type':'load_url',
				'display_url':url,
				'url':url,
				'title':$(this).attr('title')
			});

			//Prevent anchor from triggering anything else
			e.preventDefault();
			e.stopPropagation();
		}

		function send_size()
		{
			if(ignore_scroll)return;

			parent_message({
				'type':'scroll',
				//'scrollTop':$(window).scrollTop(),
				//'scrollLeft':$(window).scrollLeft()
				'scrollTop':$('.body-inner').scrollTop(),
				'scrollLeft':$('.body-inner').scrollLeft(),
			});			
		}

		//On scrolling
		$(window).on('resize', send_size);
		$('.body-inner').on('scroll', send_size);


		$('body').on('click', 'a', function(e){
			var $this = $(this);

			//Get url
			var url = $this.attr('href');

			//Ignore if empty, or just hash
			if(!url || url=='#')return;

			/*
			//removed so that will always be sent to the browser to handle
			//Ignore if not http
			if(url.indexOf('http') !== 0)return;

			//Ignore if targetting new page
			if($this.attr('target') == '_blank')return;

			//If http://modiinapp.com, change to https://
			if(url.indexOf('http://modiinapp.com') === 0)url = 'https'+url.substring(4);
			*/

			//If not to a modiinapp page, then open in browser...
			//if(url.indexOf('http://modiinapp.com') !== 0 && url.indexOf('http://192.168.1.207:8000') !== 0){
			/*
			if(true){
				e.preventDefault();
				e.stopPropagation();
				window.open(url, '_blank');
				return;
			}
			*/

			//Remove focus from element (so that underline disappears)
			//document.activeElement && document.activeElement.blur();

			//Send to app to handle
			return nav_handle(url, e);
		});

		$('body').on('submit', 'form', function(e){
			var $this = $(this);



			e.preventDefault();
			e.stopPropagation();


			//Build url
			var url = $this.prop("action")+'?'+$this.serialize();

			//Send to app to handle
			return nav_handle(url);
		});
		
		parent.postMessage(JSON.stringify({
			'type':'loaded',
			'hide_tabbar':hide_tabbar,
			'transparent_navbar':transparent_navbar,
			'show_share_button':show_share_button,
			'show_review_button':show_review_button,
			'show_bookmark_button':show_bookmark_button,
			'bookmarked':window.bookmarked?true:false,
			'title':$("meta[property='app-title']").attr("content"),
			'id':window.page_id||0,
			'alternate_url':alternate_url,
			'scrollTop':$('.body-inner').scrollTop(),
			'scrollLeft':$('.body-inner').scrollLeft(),
		}), '*');

	}

	sticky_init();

}

//Run all queued actions
for(var i = 0; i<q.length; ++i){
	q[i]();
}


