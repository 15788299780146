

var timeouttest = null;
var runagain = false;
var testcount = 0;

function processImages()
{
	//$('#debug-output').html(testcount++);

	//console.log('running real...');
	timeouttest = null;
	lazyLoadInner(false);

	if(runagain){
		runagain = false;
		lazyLoadImages(false);
	}

}


function lazyLoadImages(instant)
{
	if(timeouttest){
		runagain = true;
		return;
	}

	timeouttest = setTimeout(processImages, 250);
}


function lazy_load_init()
{
	//Lazy load images now
	lazyLoadInner(true);

	//And whenever stuff changes
	//$(window).scroll(lazyLoadImages).resize(lazyLoadImages);
	//$(window).on('scroll resize touchmove touchend', lazyLoadImages);
	//$(window).on('scroll resize', lazyLoadImages);

	//$(window).on('resize', lazyLoadImages);
	window.addEventListener('resize', lazyLoadImages);

	var bodyInner = document.querySelector('.body-inner');

	//Get the window height
	
	function scroll_monitor(el)
	{
		//console.log(el);
		//if(!el)return;

		var scrollTop = 0;
		function scroll_monitor_step()
		{
			//Get window height and scroll position
			var windowHeight = (window.innerHeight || document.documentElement.clientHeight);


			//var newScrollTop = el.scrollTop;

			var newScrollTop = window.pageYOffset;

			if(bodyInner){
				newScrollTop += bodyInner.scrollTop;
			}

			//console.log(scrollTop, newScrollTop, windowHeight);

			/*
			if(in_iframe()){
				newScrollTop += window.parent.scrollY;
				windowHeight = window.parent.innerHeight;
			}*/

			/*
			//Shouldn't be needed now...
			if(app_mode()){
				if(window.scroll_pos){
					newScrollTop += window.scroll_pos.y;
					windowHeight = window.scroll_pos.h;
				}
				else{
					if(windowHeight > 600)windowHeight = 600;
				}
			}*/

			//If scroll distance between now and last check is greater than half a window height, then check again
			if(Math.abs(scrollTop - newScrollTop) > windowHeight/2){
				scrollTop = newScrollTop;
				lazyLoadImages();
			}
		}
		setInterval(scroll_monitor_step, 100);
	}

	scroll_monitor();
	//scroll_monitor(document);
	//scroll_monitor(window.body);
	//scroll_monitor(document.querySelector('.body-inner'));
}